import React, {useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Datamap from 'datamaps/dist/datamaps.usa.min.js';
import d3 from 'd3';
import { useSelectGARegionDataQuery } from "../../app/api";
import { selectGAWidgetData, selectGAWidgetsPending } from "../../features/currentView/currentViewSlice";
import { selectWidgetFilters } from "../../features/currentView/currentViewSlice";
import { createGAQuery, createGAHeatmapObject, createNullHeatmapObject } from '../../data/WidgetData';
import { selectCurrentHeadlineDateRange } from "../../features/audiences/audienceSlice";
import Loading from "../UIElements/Loading";
import PropTypes from "prop-types";
import { topicMappings } from "../../data/HeadlineData";
import { mergeByTopic } from "../ClientDashboard/Insights";
import { selectInTopics } from "../../features/currentView/currentViewSlice";

export default function GAHeatMap({selectedAudience}) {

  const widgetData = useSelector(selectGAWidgetData);
  const widgetsPending = useSelector(selectGAWidgetsPending);
  const filter = useSelector(selectWidgetFilters);
  const isPending = widgetsPending['state'];
  const currentHeadlineDateRange = useSelector(selectCurrentHeadlineDateRange);
  const savedTopics = useSelector(selectInTopics);
  const [inTopicString, setInTopicString] = useState('all');
  const [outTopicString] = useState('none');
  const [gaQueryString, setGaQueryString] = useState(createGAQuery(filter, currentHeadlineDateRange));

  const [rawdata, setRawdata] = useState([]);

  const {refetch: refetchData, isLoading: dataLoading} = useSelectGARegionDataQuery({
    type: 'state',
    filter_query_string: gaQueryString,
    audience_code: selectedAudience?.ga_code || selectedAudience?.base_ga_code,
    include: inTopicString,
    exclude: outTopicString
  }
  );

  useEffect(() => {
    if (savedTopics.length > 0 && savedTopics[0].length > 0 ) {
      let newArray = mergeByTopic(savedTopics, topicMappings);
      setInTopicString(newArray.join(",")); 
    }
    else {
      setInTopicString('all'); 
    }

  }, [savedTopics]);

  useEffect(() => {
    setGaQueryString(createGAQuery(filter, currentHeadlineDateRange));
  }, [filter]) 

  useEffect(() => {
    setGaQueryString(createGAQuery(filter, currentHeadlineDateRange));
  }, [currentHeadlineDateRange])

  useEffect(() => {
    if(widgetData['state'].length > 0) {
        if (widgetData['state'][0] == 'none') {
          setRawdata(createNullHeatmapObject());
        }
        else {
          setRawdata(createGAHeatmapObject(widgetData['state']));
        }
    }
    else {
      if (!dataLoading && widgetData['state'].length == 0) {
        refetchData();
      } 
    }
  }, [widgetData['state']]);

  useEffect(() => {
    if ((widgetData['state']?.length >= 1 && widgetData['state'][0]?.audience == (selectedAudience?.ga_code || selectedAudience?.base_ga_code)) || (widgetData['state'] && widgetData['state'][0] == 'none') ) { 
      const element = document.getElementById('usa_map');
      if (element) {
        element.remove();
      }
      const map_element = document.createElement('div');
      map_element.id = 'usa_map';
      const parent_element = document.getElementById('usa_map_holder');
      parent_element.appendChild(map_element);

      let dataset = {};
      let onlyValues = Object.values(rawdata);
      let minValue = Math.min.apply(null, onlyValues),
          maxValue = Math.max.apply(null, onlyValues);

      let paletteScale = d3.scale.linear()
        .domain([minValue, maxValue])
        .range(["#EFEFFF", "#02386F"]); // blue color


      for (let key in rawdata) {
        let iso = key,
            value = rawdata[key];
        dataset[iso] = { numberOfThings: value, fillColor: paletteScale(value) };
      }

      const map = new Datamap({
        scope: 'usa',
        element: document.getElementById('usa_map'),
        height: 400,
        geographyConfig: {
          highlightOnHover: false,
          borderColor: '#444',
          highlightBorderWidth: 1,
          borderWidth: 0.5,
        },
        data: dataset
      });
      map.labels({fontFamily: 'Ubuntu, Poppins, Verdana'});
    }
  }, [rawdata]);

  const loadingMap = () => {
    return (
      <>
        {(isPending || !rawdata) && <Loading />}
        <div id='usa_map_holder' className='smaller' />
      </>
    )
  }


  return <>
    {loadingMap()}
  </>
}

GAHeatMap.propTypes = {
  selectedAudience: PropTypes.object,
}